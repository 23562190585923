import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useQueryString } from '@kaliber/sanity-routing/queryString'
import { getPagination } from '@kaliber/pagination'

import { useTranslate } from '/machinery/I18n'
import { SearchContextProvider } from '/machinery/SearchContext'
import { useClientConfig } from '/machinery/ClientConfig'
import { ensureIntegerLargeOrEqual } from '/machinery/ensure'

import { useRubrics, useSearchArticlesCanonical, useTags } from '/features/search/useSearchArticles'
import { MenuOverview } from '/features/pageOnly/menu/Menu'
import { ContainerLg, ContainerMd } from '/features/buildingBlocks/Container'
import { ArticleCard } from '/features/article/buildingBlocks/ArticleCard'
import { Logo } from '/features/regionArticles/Logo'
import { SearchForm } from '/features/buildingBlocks/SearchForm'
import { ImageCover } from '/features/buildingBlocks/Image'
import { ButtonIconWithLabelReverse } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'
import { FooterPage } from '/features/pageOnly/Footer'
import { HeadingXs, HeadingSm } from '/features/buildingBlocks/Heading'
import { PopupContentDefault } from '/features/regionArticles/cards/popups/content/PopupContentDefault'
import { ModalMd } from '/features/buildingBlocks/Modal'
import { TagButton } from '/features/buildingBlocks/TagButton'

import media from '/cssGlobal/media.css'
import styles from './ArticlesOverview.css'

import iconMark from '/images/icons/mark.raw.svg'
import iconChevronRight from '/images/icons/chevron-right.raw.svg'
import iconChevronLeft from '/images/icons/chevron-left.raw.svg'
import iconFilters from '/images/icons/filters.raw.svg'
import iconPlus from '/images/icons/icon-plus.raw.svg'
import iconTrashcan from '/images/icons/trashcan.raw.svg'

export default function ArticlesOverview({ searchResults, heroImage }) {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <SearchContextProvider>
        <Overview initialSearchResults={searchResults} {...{ heroImage }} />
      </SearchContextProvider>
    </QueryClientProvider>
  )
}

function Overview({ initialSearchResults, heroImage }) {
  const config = useClientConfig()
  const { resultsPerPage } = config.pagination

  const [{ page: rawPage }, setQueryString] = useQueryString()
  const page = ensureIntegerLargeOrEqual(rawPage, 1)

  const { search, results } = useSearchArticlesCanonical({
    initialData: initialSearchResults,
    size: 12,
    from: (page - 1) * resultsPerPage,
    enabled: () => true
  })

  const { articles, total } = results

  const { tags, handleTagClick } = useTags()
  const { rubrics, handleRubricClick } = useRubrics()
  const totalActiveFilters = [...tags, ...rubrics].filter(x => x.isActive).length

  return (
    <div className={styles.componentOverview}>
      <MenuOverview layoutClassName={styles.menuLayout} />

      <SearchHero
        searchValue={search.value}
        onSearchChange={handleSearchChange}
        onTagClick={handleTagClick}
        onRubricClick={handleRubricClick}
        layoutClassName={styles.searchHeroLayout}
        {...{ heroImage, tags, rubrics, totalActiveFilters }}
      />

      <FilterTags
        onTagClick={handleTagClick}
        onRubricClick={handleRubricClick}
        onRemovellFilters={handleRemovellFilters}
        layoutClassName={styles.filterTagsLayout}
        {...{ tags, rubrics, totalActiveFilters }}
      />

      <ContainerLg>
        <OverviewBody
          onPaginationClick={handlePaginationClick}
          {...{ articles, total, page, resultsPerPage }}
        />
      </ContainerLg>

      <FooterPage layoutClassName={styles.footerLayout} />
    </div>
  )

  function handleSearchChange(x) {
    search.setValue(x)
    setQueryString(x => ({ ...x, page: undefined }))
  }

  function handlePaginationClick(rawPage) {
    const page = ensureIntegerLargeOrEqual(rawPage, 1)

    setQueryString(x => ({ ...x, page }))

    window.scrollTo({ top: 0, behavior: 'instant' })
  }

  function handleRemovellFilters() {
    setQueryString(x => ({ ...x, tags: undefined, rubrics: undefined }))
  }
}

function SearchHero({
  heroImage,
  searchValue,
  tags,
  rubrics,
  totalActiveFilters,
  onSearchChange,
  onTagClick,
  onRubricClick,
  layoutClassName,
}) {
  const [filterModalIsOpen, setFilterModalIsOpen] = React.useState(false)

  return (
    <div className={cx(styles.componentSearchHero, layoutClassName)}>
      {filterModalIsOpen && (
        <FilterModal
          modalIsOpen={filterModalIsOpen}
          onModalClose={handleModalClose}
          {...{ tags, rubrics, onTagClick, onRubricClick }}
        />
      )}

      <div className={styles.heroImageContainer}>
        <ImageCover
          image={heroImage}
          aspectRatio={16 / 9}
          layoutClassName={styles.imageLayout}
        />
      </div>

      <ContainerMd>
        <div className={styles.heroContainer}>
          <Logo layoutClassName={styles.logoLayout} />

          <Title />

          <div className={styles.searchFormContainer}>
            <SearchForm
              value={searchValue}
              onChange={onSearchChange}
              layoutClassName={styles.searchLayout}
            />

            <FilterModalButton
              onClick={handleModalOpenChange}
              layoutClassName={styles.filterModalButtonLayout}
              {...{ totalActiveFilters }}
            />
          </div>
        </div>
      </ContainerMd>
    </div>
  )

  function handleModalOpenChange() {
    setFilterModalIsOpen(!filterModalIsOpen)
  }

  function handleModalClose() {
    setFilterModalIsOpen(false)
  }
}

function FilterModalButton({ onClick, totalActiveFilters, layoutClassName }) {
  return (
    <button
      type='button'
      data-x='open-filter-modal'
      className={cx(styles.componentFilterModalButton, layoutClassName)}
      {...{ onClick }}
    >
      <Icon icon={iconFilters} layoutClassName={styles.iconLayout} />
      {(totalActiveFilters > 0) && <span className={styles.activeFiltersCount}>{totalActiveFilters}</span>}
    </button>
  )
}

function Title() {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentTitle}>
      <h1 className={styles.title}>{__`articles-overview-title`}</h1>
      <p className={styles.subtitle}>{__`articles-overview-subtitle`}</p>
    </div>
  )
}

function FilterTags({ tags, rubrics, totalActiveFilters, onTagClick, onRubricClick, onRemovellFilters, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentFilterTags, layoutClassName)}>
      <div className={styles.filterTagsContainer}>
        {tags.filter(x => x.isActive).map(({ label, value, buttonId }) => (
          <TagButton
            key={value}
            dataX='remove-tag-filter'
            icon={iconMark}
            onClick={() => onTagClick(value)}
            {...{ label, buttonId }}
          />
        ))}

        {rubrics.filter(x => x.isActive).map(({ label, value, buttonId }) => (
          <TagButton
            key={value}
            dataX='remove-rubric-filter'
            icon={iconMark}
            onClick={() => onRubricClick(value)}
            {...{ label, buttonId }}
          />
        ))}

        {(totalActiveFilters >= 2) && (
          <ButtonIconWithLabelReverse
            label={__`remove-all-filters`}
            icon={iconTrashcan}
            dataX='remove-all-filters'
            onClick={onRemovellFilters}
            layoutClassName={styles.expandButtonLayout}
          />
        )}
      </div>
    </div>
  )
}

function OverviewBody({ articles, total, page, resultsPerPage, onPaginationClick }) {
  const { __ } = useTranslate()

  const totalPages = Math.ceil(total / resultsPerPage)

  const pagination = getPagination({
    current: page,
    max: totalPages,
    padding: 1
  })

  return (
    <div className={styles.componentOverviewBody}>
      <div className={styles.overviewBodyTextContainer}>
        <HeadingSm h={2}>{__`search-results`}:</HeadingSm>
        <ArticlesFoundNumber {...{ total }} />
      </div>

      <ArticlesGrid {...{ articles }} />

      {Boolean(pagination.length) && (
        <Pagination
          layoutClassName={styles.paginationLayout}
          {...{ pagination, page, onPaginationClick, totalPages }}
        />
      )}
    </div>
  )
}

function ArticlesFoundNumber({ total }) {
  const { __ } = useTranslate()

  const label = (total !== 1) ? __`articles-found` : __`article-found`

  return <p className={styles.componentArticlesFoundNumber}>{total} {label}</p>
}

function Pagination({ pagination, page, totalPages, onPaginationClick, layoutClassName }) {
  return (
    <div className={cx(styles.componentPagination, layoutClassName)}>
      <PaginationButtonChevron
        disabled={page <= 1}
        onClick={() => onPaginationClick(page - 1)}
        dataX='click-previous-page'
        icon={iconChevronLeft}
        layoutClassName={styles.paginationButtonLayout}
      />

      {pagination.map((x, i) => x ? (
        <PaginationButton
          key={i}
          page={x}
          isCurrent={x === page}
          onClick={() => onPaginationClick(x)}
          layoutClassName={styles.paginationButtonLayout}
        />
      ) : '...')}

      <PaginationButtonChevron
        disabled={page >= totalPages - 1}
        onClick={() => onPaginationClick(page + 1)}
        dataX='click-next-page'
        icon={iconChevronRight}
        layoutClassName={styles.paginationButtonLayout}
      />
    </div>
  )
}

function PaginationButton({ onClick, page, isCurrent, layoutClassName }) {
  return (
    <button
      type='button'
      data-x='click-pagination'
      className={cx(styles.componentPaginationButton, isCurrent && styles.isCurrent, layoutClassName)}
      {...{ onClick }}
    >
      {page}
    </button>
  )
}

function PaginationButtonChevron({ onClick, dataX, icon, layoutClassName, disabled = false }) {
  return (
    <button
      type='button'
      data-x={dataX}
      className={cx(styles.componentPaginationButtonChevron, layoutClassName)}
      {...{ onClick, disabled }}
    >
      <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
    </button>
  )
}

function ArticlesGrid({ articles }) {
  const { __ } = useTranslate()

  const sizes = `
    (min-width: ${media.breakpointLg}) calc(var(--container-max-width-md) / 3),
    (min-width: ${media.breakpointMd}) calc(var(--container-max-width-md) / 2),
    90vw
  `

  return (
    <ul className={styles.componentArticlesGrid}>
      {articles?.length
        ? articles.map(item => <ArticleCard key={item.id} {...{ sizes, item }} />)
        : <p>{__`no-articles-found`}</p>
      }
    </ul>
  )
}

function FilterModal({ tags, onTagClick, rubrics, onRubricClick, modalIsOpen, onModalClose }) {
  return (
    <ModalMd
      active={modalIsOpen}
      onClose={onModalClose}
      content={
        <PopupContentDefault renderCard={({ layoutClassName }) => (
          <FilterModalContent {...{ tags, onTagClick, rubrics, onRubricClick, layoutClassName }} />
        )} />
      }
    />
  )
}

function FilterModalContent({ tags, onTagClick, rubrics, onRubricClick, layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentFilterModalContent, layoutClassName)}>
      <HeadingSm h={3}>Filters</HeadingSm>

      <FilterItems
        title={__`tags`}
        type='tags'
        items={tags}
        onFilterClick={onTagClick}
      />

      <FilterItems
        title={__`rubrics`}
        type='rubrics'
        items={rubrics}
        onFilterClick={onRubricClick}
      />
    </div>
  )

}

function FilterItems({ title, items, onFilterClick, type }) {
  const { __ } = useTranslate()

  const [isShowingAllItems, setIsShowingAllItems] = React.useState(false)
  const slicedItems = isShowingAllItems ? items : items.slice(0, 5)

  return (
    <div className={styles.componentFilterItems}>
      <HeadingXs h={4}>{title}</HeadingXs>

      <ul className={styles.filterItemsContainer}>
        {slicedItems.map(({ isActive, label, value, buttonId }) => (
          <TagButton
            key={buttonId}
            dataX={`filter-${type}`}
            icon={isActive ? iconMark : null}
            onClick={() => onFilterClick(value)}
            {...{ isActive, label, value, buttonId }}
          />
        ))}
      </ul>

      <ButtonIconWithLabelReverse
        label={isShowingAllItems ? __`hide-some-filters` : __`show-more-filters`}
        icon={isShowingAllItems ? null : iconPlus}
        dataX={`show-all-${type}`}
        onClick={handleShowAllItems}
        layoutClassName={styles.expandButtonLayout}
      />
    </div>
  )

  function handleShowAllItems() {
    setIsShowingAllItems(!isShowingAllItems)
  }
}
